import { getTitleFromListing } from 'lib/getTitleFromListing';
import { Listing } from 'types';
import { truncate } from 'utils/truncate';

export const getListingTitleTruncated = (
  listing: Listing,
  options: { truncate?: number; lines?: 0 | 2 | 3 } = { truncate: 45, lines: 0 }
) => {
  const title = getTitleFromListing(listing, false);

  if (options.lines === 2) {
    return <div className='clamp-lines-2'>{title}</div>;
  }
  if (options.lines === 3) {
    return <div className='clamp-lines-3'>{title}</div>;
  }

  return truncate(title, options.truncate || 45);
};
