import { ISortByObjectSorter, sort } from 'fast-sort';

export const getSortedList = <T>(list: T[], key: keyof T, asc = false) => {
  if (key) {
    const sorting: ISortByObjectSorter<T & { updated: string }>[] = asc
      ? [{ asc: u => u[key] }, { desc: u => u.updated }]
      : [{ desc: u => u[key] }, { desc: u => u.updated }];
    const sortedList = sort(list).by(sorting as ISortByObjectSorter<T>[]);

    return sortedList;
  } else if (key === 'updated') {
    const sorting: ISortByObjectSorter<T>[] = asc ? [{ asc: u => u[key] }] : [{ desc: u => u[key] }];
    const sortedList = sort(list).by(sorting);

    return sortedList;
  }

  return sort(list).desc('updated' as keyof T);
};
