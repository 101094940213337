import { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import useSWR from 'swr';

import { clientApiGet } from 'lib/api_helper';
import { apiUrls } from 'lib/api_urls';
import { Model, PaginatedCatalogSearch } from 'types';

export const useGetListingModels = () => {
  const [filteredQuery, setFilteredQuery] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(0);

  const { data } = useSWR<PaginatedCatalogSearch>(
    apiUrls.catalogSearch({
      type: 'MODEL',
      page: currentPage,
      query: filteredQuery || '*'
    }),
    clientApiGet
  );
  const [models, setModels] = useState<Model[]>([]);

  useEffect(() => {
    if (data?.hits.length) {
      // sometimes the object is null
      const filteredModels = data.hits.map(({ object }) => object).filter(Boolean);

      setModels(oldModels => [...oldModels, ...filteredModels]);
    }
  }, [data]);

  const paginationCallback = useCallback(() => {
    if (data && models.length < data.totalHitCount) {
      setCurrentPage(data?.page + 1);
    }
  }, [data, models]);

  // it is required here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUpdateQuery = useCallback(
    throttle(
      (val: string) => {
        setModels([]);
        setCurrentPage(0);
        setFilteredQuery(val);
      },
      100,
      { trailing: true }
    ),
    []
  );

  return {
    onUpdateQuery,
    filteredQuery,
    models,
    paginationCallback,
    isEmpty: data && models.length === 0,
    isLoading: !data && models.length === 0
  };
};
