import { FC } from 'react';
import classNames from 'classnames';

import { Button } from 'components/design-system/Button';
import { useBezelUser } from 'context/bezelUser';
import { useTheme } from 'context/theme';
import { intercomShutdown } from 'lib/intercom';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/TopNav.module.scss';

export const TopNavAuthMobile: FC = () => {
  const { user } = useBezelUser();
  const { isDark } = useTheme();

  return (
    <div id='auth-items' className='d-flex flex-column px-4'>
      {user ? (
        <Button className={`rounded-2 fs-12px ${styles.navCTA}`} href='/api/auth/logout' onClick={intercomShutdown}>
          Log Out
        </Button>
      ) : (
        <>
          <Button
            id='signup-link'
            variant='primary'
            roundedClassName=''
            className={classNames('px-4 fs-12px fs-md-14px mb-1 border-radius-20', styles.navCTA, {
              [styles.authButtonDark]: isDark
            })}
            href={pageRoutes.signUp}
          >
            Join Bezel
          </Button>
          <Button
            id='signup-link'
            variant='secondary'
            className={classNames('rounded-2 fs-12px', styles.navCTA, {
              [styles.darkNav]: isDark,
              [styles.brightOnHover]: !isDark
            })}
            href={pageRoutes.login}
          >
            Log in
          </Button>
        </>
      )}
    </div>
  );
};
