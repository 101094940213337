import { FC } from 'react';
import { Nav } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { Button } from 'components/design-system/Button';
import { ContentLoader } from 'components/design-system/ContentLoader';
import { useBezelUser } from 'context/bezelUser';
import { useTheme } from 'context/theme';
import { intercomShutdown } from 'lib/intercom';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/TopNav.module.scss';

import { dropdownItems } from '../../NavbarDropdown/consts';
import { TopNavAuthDropdownItem } from '../../TopNavAuthDropdownItem';

export const TopNavAuthDesktop: FC = () => {
  const { push } = useRouter();
  const { user, isLoading } = useBezelUser();
  const { isDark } = useTheme();

  return (
    <div
      id='auth-items'
      className={classNames('d-flex flex-row-reverse align-items-center border-start px-0 ps-lg-1 ps-xl-3', {
        [styles.borderDark]: isDark
      })}
    >
      {isLoading && (
        <div className='ms-3 d-flex align-items-center' style={{ height: 37 }}>
          <ContentLoader className='d-flex' borderRadius={16} width={95} height={25} />
        </div>
      )}
      {user && !isLoading && (
        <NavDropdown
          title={user.givenName}
          align='end'
          menuVariant='light'
          className={isDark ? styles.dropdownDark : undefined}
        >
          {dropdownItems.map(({ href, title }) => (
            <TopNavAuthDropdownItem key={title} onClick={() => push(href)} title={title} />
          ))}
          <NavDropdown.Divider />
          <TopNavAuthDropdownItem href='/api/auth/logout' onClick={intercomShutdown} title='Log Out' />
        </NavDropdown>
      )}
      {!user && !isLoading && (
        <>
          <Button
            id='signup-link'
            variant='primary'
            roundedClassName=''
            className={classNames(
              'border-radius-16 px-4 px-xl-5 py-lg-2 fs-14px mr-lg-05 mb-1 mb-lg-0',
              styles.navCTA,
              {
                [styles.authButtonDark]: isDark
              }
            )}
            href={pageRoutes.signUp}
          >
            Sign Up
          </Button>
          <Nav.Link
            id='login-link'
            className={classNames(styles.navItem, 'me-2 me-xl-3', {
              [styles.darkNav]: isDark,
              [styles.brightOnHover]: !isDark
            })}
            href={pageRoutes.login}
          >
            Log In
          </Nav.Link>
        </>
      )}
    </div>
  );
};
