import { FC, useEffect, useRef } from 'react';

import { Pill } from 'components/design-system/Pill';
import { Slideout, SlideoutCommonProps, SubmitButtonWithArrow } from 'components/design-system/Slideout';
import { useAnalytics } from 'context/analytics';
import { useModal } from 'context/modal/ModalContext';
import { usePurchaseRequest } from 'context/PurchaseRequest';
import { useViewport } from 'context/viewport';
import { useHideIntercom } from 'hooks';
import { logViewedRequestAnnouncement } from 'lib/events';

import { WatchBackground } from './WatchBackground';

import { SelectModelSlideout } from '../SelectModelSlideout';

export const LookingForWatchSlideout: FC<SlideoutCommonProps> = ({ ...props }) => {
  const { closeModal, openModal } = useModal();
  const { setIsUrgencySlideoutVisible } = usePurchaseRequest();
  const { isMobile } = useViewport();
  const contentRef = useRef<HTMLDivElement>(null);
  const isLoggedViewedRequestAnnouncement = useRef(false);
  const analytics = useAnalytics();

  useHideIntercom();

  useEffect(() => {
    setIsUrgencySlideoutVisible(true);
  }, [setIsUrgencySlideoutVisible]);
  const openSelectModelSlideout = () => openModal(<SelectModelSlideout closeModal={closeModal} />, true);
  const onShopAllClick = () => {
    props.closeModal?.();
  };

  const onShow = () => contentRef.current?.scrollIntoView();

  useEffect(() => {
    if (isLoggedViewedRequestAnnouncement.current) {
      return;
    }
    isLoggedViewedRequestAnnouncement.current = true;
    logViewedRequestAnnouncement(analytics);
  }, [analytics]);

  return (
    <Slideout
      title
      showAnyQuestions
      onShow={onShow}
      onClickPrevious={undefined}
      bodyClassName='px-0'
      {...props}
      submit={{
        onClick: openSelectModelSlideout,
        textClassname: 'w-100',
        title: <SubmitButtonWithArrow text='Get started' />
      }}
      cancel={{ onClick: onShopAllClick, title: 'Shop all watches' }}
    >
      {!isMobile && <WatchBackground />}
      <div className='d-flex flex-column align-items-center text-center px-4'>
        <Pill title='NEW FEATURE' />
        <h3 className='fs-24px line-height-32px letter-spacing-007 px-3 mt-3'>A new way to source watches</h3>
        <div ref={contentRef} className='riforma-regular opacity-60 fs-16px letter-spacing-006 mt-2 mt-lg-4 px-2'>
          Introducing Purchase Requests - a new feature that allows you to request the exact model you’re looking for at
          the price you want in just a few simple steps.
        </div>
      </div>
      {isMobile && <WatchBackground />}
    </Slideout>
  );
};
