const ending = '...';

export const truncate = (str: string | undefined, length: number) => {
  if (!str || str.length <= length) {
    return str;
  }

  const end = length - ending.length;
  const truncatedString = str.slice(0, end);

  return truncatedString + ending;
};
