import { HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import classNames from 'classnames';

import styles from './Input.module.scss';

// google recommends to set id or name on all inputs so it can better autocomplete and crawl them
type IdOrName = { id: string } | { name: string };

type InputProps = {
  value?: string | number;
  setValue: (value: string) => void;
  isResponsive?: boolean;
  autoFocus?: boolean;
  isError?: boolean;
  errorMessage?: React.ReactNode;
  getValidationException?: (val: string) => string | null;
  errorClassName?: string;
  wrapClassName?: string;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
} & HTMLAttributes<HTMLInputElement> &
  IdOrName;

export const Input: React.FC<InputProps> = ({
  value,
  setValue,
  placeholder,
  className,
  isResponsive,
  autoFocus = false,
  isError,
  errorMessage,
  onBlur,
  getValidationException,
  errorClassName,
  wrapClassName,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [validationError, setValidationError] = useState<string | null>(null);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      setValue(e.target.value);
      setValidationError(null);
    },
    [setValue]
  );

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  const onBlurLocal = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (getValidationException) {
        const errorMsg = getValidationException(e.target.value);

        if (errorMsg) {
          setValidationError(errorMsg);
        }
      }
      onBlur?.(e);
    },
    [getValidationException, onBlur]
  );

  useEffect(() => {
    if (inputRef.current && isResponsive && value) {
      inputRef.current.setAttribute('size', `${value.toString().length}`);
    }
  }, [isResponsive, value]);

  return (
    <div className={wrapClassName}>
      <Form.Control
        ref={inputRef}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoFocus={autoFocus}
        className={classNames(
          className,
          { 'text-danger': isError || validationError },
          styles.input,
          isError || validationError || errorMessage ? styles['is-error'] : null
        )}
        onBlur={onBlurLocal}
        {...rest}
      />
      <Form.Control.Feedback className={classNames('text-danger fs-12px d-block text-start m-0', errorClassName)}>
        {validationError || errorMessage}
      </Form.Control.Feedback>
    </div>
  );
};
