import { FC } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import classNames from 'classnames';
import useSWR from 'swr';

import { Link } from 'components/common/Link';
import { SearchResultCard } from 'components/search/SearchResultCard';
import { COLORS } from 'consts';
import { useTheme } from 'context/theme';
import { clientApiGet } from 'lib/api_helper';
import styles from 'styles/SearchResults.module.scss';
import { ListingStat, PaginatedCatalogSearch, StyledComponent } from 'types';

import { useSearchAnalytics } from '../../hooks/analytics/useSearchAnalytics';

type SearchResultsProps = {
  query: string;
  searchResults?: PaginatedCatalogSearch;
  setHasNavigated: () => void;
} & StyledComponent;

/**
 * Search results, retrieves the listing stats for all search results displayed.
 */
export const SearchResults: FC<SearchResultsProps> = ({
  query,
  searchResults: searchResultsFromProps,
  setHasNavigated,
  className
}) => {
  const { triggerSearchInteractionEvents } = useSearchAnalytics({ query });
  const handleAllWatchesClick = () => {
    setHasNavigated();
  };

  const handleAllResultsClick = () => {
    triggerSearchInteractionEvents();
    setHasNavigated();
  };
  const { isDark } = useTheme();

  const searchResults = searchResultsFromProps;

  if (searchResults) {
    searchResults.hits = searchResults?.hits?.filter(m => !!m.object);
  }
  const modelIds = searchResults?.hits?.map(m => m.object.id);
  const { data: listingStats } = useSWR<ListingStat[]>(
    modelIds ? `/api/batchListings/stats?modelIds=${modelIds}` : null,
    clientApiGet
  );

  if (query.length < 3 && !searchResults) {
    return null;
  }

  if (!searchResults) {
    return (
      <div className={`${className} text-center py-3`}>
        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
      </div>
    );
  }

  if (!listingStats) {
    return (
      <div className={`${className} text-center py-3`}>
        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
      </div>
    );
  }

  // Attach the min price of listings, if applicable, to each search result [model] hit
  for (const hit of searchResults.hits) {
    const id = hit.object.id;

    hit.object.lowestPrice = listingStats[id].minPrice;
  }

  const hits = searchResults.hits;

  const renderedResults = [];

  for (let i = 0; i < hits.length; i++) {
    if (hits[i] !== undefined) {
      renderedResults.push(
        <SearchResultCard key={`search-result-${i}`} model={hits[i].object} setHasNavigated={setHasNavigated} />
      );
      renderedResults.push(<hr key={`search-results-line-${i}`} className='w-90 mx-auto my-2' />);
    }
  }

  return (
    <div className={className} style={{ color: isDark ? COLORS.primaryDark : undefined }}>
      <div className={`overflow-auto ${styles.searchResultsContainer}`}>{renderedResults}</div>
      {hits.length === 0 ? (
        <Link key='search-results-empty' href='/explore' onClick={handleAllWatchesClick}>
          <div className='text-center riforma-medium '>
            <div className='text-lightPrimaryText fs-12px'>NO RESULTS</div>
            <div className='text-success fs-14px'>BROWSE ALL WATCHES</div>
          </div>
        </Link>
      ) : (
        <Link
          key='search-results-all'
          href={{
            pathname: '/explore',
            query: {
              searchQuery: query
            }
          }}
          as={`/explore?searchQuery=${query}`}
          onClick={handleAllResultsClick}
        >
          <div
            className={classNames(
              'text-center riforma-medium fs-14px pt-2',
              isDark ? 'text-turquoise' : 'text-success'
            )}
          >
            ALL RESULTS
          </div>
        </Link>
      )}
    </div>
  );
};
