import { FC, useCallback } from 'react';
import Nav from 'react-bootstrap/Nav';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import { useTheme } from 'context/theme';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/TopNav.module.scss';

import { NavlinkShopDesktop } from './NavLinkShopDesktop';

import { NavlinkSell } from '../../NavlinkSell';

type ShopItemsDesktopProps = {
  setNavDropdownDesktopIsOpened: (val: boolean) => void;
  setNavDropdownDesktopWasForcedClosed: (val: boolean) => void;
  navDropdownDesktopIsOpened: boolean;
};

export const ShopItemsDesktop: FC<ShopItemsDesktopProps> = ({
  setNavDropdownDesktopIsOpened,
  setNavDropdownDesktopWasForcedClosed,
  navDropdownDesktopIsOpened
}) => {
  const router = useRouter();
  const { isDark } = useTheme();
  const pathname = usePathname();
  const classes = isDark ? styles.darkNav : `${styles.navItem} ${styles.brightOnHover}`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleNavbarDropdownOn = useCallback(
    throttle(
      () => {
        setNavDropdownDesktopIsOpened(true);
        setNavDropdownDesktopWasForcedClosed(false);
      },
      100,
      { leading: true, trailing: false }
    ),
    []
  );

  const toggleNavbarDropdownOff = debounce(() => setNavDropdownDesktopIsOpened(false), 200);
  const onClickShopButton = () => {
    router.push(pageRoutes.explore());
    toggleNavbarDropdownOff();
  };

  const isAuctionsPage = pathname?.includes('/auctions');

  return (
    <div id='nav-items' className='d-flex align-items-center mr-1 overflow-auto'>
      <NavlinkShopDesktop
        className={classes}
        onMouseMove={toggleNavbarDropdownOn}
        onMouseLeave={toggleNavbarDropdownOff}
        onClick={onClickShopButton}
        isActive={navDropdownDesktopIsOpened}
      />
      <Link legacyBehavior passHref href={pageRoutes.auctions}>
        <Nav.Link
          active={isAuctionsPage}
          className={classNames(classes, {
            'opacity-100': isAuctionsPage
          })}
        >
          Auctions
        </Nav.Link>
      </Link>
      <div className={styles.border}>
        <NavlinkSell className={classes} />
      </div>
      <div className={styles.border}>
        <Nav.Link
          active={false}
          className={classes}
          target='_blank'
          rel='noreferrer'
          href='https://getbezel.com/journal'
        >
          Journal
        </Nav.Link>
      </div>
    </div>
  );
};
