import { ExploreQueryParams } from 'components/explore/helpers/transformFilters';
import { convertToList } from 'lib/helpers';

export const constructSearchUrl = (query: string, queryParams: ExploreQueryParams, searchUrl: string) => {
  const priceLowerBound = queryParams.priceLowerBound ? Number(queryParams.priceLowerBound) : undefined;
  const priceUpperBound = queryParams.priceUpperBound ? Number(queryParams.priceUpperBound) : undefined;
  const caseSizeLowerBound = queryParams.caseSizeLowerBound ? Number(queryParams.caseSizeLowerBound) : undefined;
  const caseSizeUpperBound = queryParams.caseSizeUpperBound ? Number(queryParams.caseSizeUpperBound) : undefined;

  const hasQueryOrFilters =
    query !== '*' ||
    (queryParams.brands && queryParams.brands.length > 0) ||
    (queryParams.series && queryParams.series.length > 0) ||
    priceUpperBound ||
    priceLowerBound ||
    caseSizeUpperBound ||
    caseSizeLowerBound ||
    (queryParams.caseMaterials && queryParams.caseMaterials.length > 0) ||
    (queryParams.dialColors && queryParams.dialColors.length > 0) ||
    queryParams.complications;

  // dial colors filter
  if (queryParams.dialColors && queryParams.dialColors.length > 0) {
    const wrappedDialColors = convertToList(queryParams.dialColors);

    for (const dialColor of wrappedDialColors) {
      searchUrl += `&dialColorIds[]=${dialColor}`;
    }
  }
  // case materials filter
  if (queryParams.caseMaterials && queryParams.caseMaterials.length > 0) {
    const wrappedCaseMaterials = convertToList(queryParams.caseMaterials);

    for (const caseMaterial of wrappedCaseMaterials) {
      searchUrl += `&caseMaterialIds[]=${caseMaterial}`;
    }
  }

  // price range filter
  if (priceLowerBound && priceLowerBound > 0) {
    const lowerCents = priceLowerBound * 100;

    searchUrl += `&priceLowerBound=${lowerCents}`;
  }

  if (priceUpperBound && priceUpperBound > 0) {
    const upperCents = priceUpperBound * 100;

    searchUrl += `&priceUpperBound=${upperCents}`;
  }

  // case size filter
  if (caseSizeLowerBound && caseSizeLowerBound > 0 && caseSizeLowerBound < 46) {
    searchUrl += `&caseSizeLowerBound=${caseSizeLowerBound}`;
  }

  if (caseSizeUpperBound && caseSizeUpperBound > 0 && caseSizeUpperBound > 22) {
    searchUrl += `&caseSizeUpperBound=${caseSizeUpperBound}`;
  }

  // brand filter
  if (queryParams.brands && queryParams.brands.length > 0) {
    const wrappedBrands = convertToList(queryParams.brands);

    for (const brand of wrappedBrands) {
      searchUrl += `&brandId[]=${brand}`;
    }
  }

  // series filter
  if (queryParams.series && queryParams.series.length > 0) {
    const wrappedSeries = convertToList(queryParams.series);

    for (const series of wrappedSeries) {
      searchUrl += `&seriesId[]=${series}`;
    }
  }

  // complications filter
  if (queryParams.complications && queryParams.complications.length > 0) {
    const wrappedComplications = convertToList(queryParams.complications);

    for (const complication of wrappedComplications) {
      searchUrl += `&complicationId[]=${complication}`;
    }
  }

  if (query === '*') {
    searchUrl += '&sort[]=mostRecentListing';
  }

  if (!hasQueryOrFilters) {
    searchUrl += '&hasModelPhoto=true';
  }

  return searchUrl;
};
