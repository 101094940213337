import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useRouter } from 'next/router';

import { Slideout } from 'components/design-system/Slideout';
import { useBezelUser } from 'context/bezelUser';
import { clientApiPatch } from 'lib/api_helper';

const inputClasses = 'rounded-1 p-3 bg-transparent';

export const CompleteProfileModal = () => {
  const { user, refresh } = useBezelUser();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [mounted, setMounted] = useState(false);
  const router = useRouter();
  const isOnCheckoutPage = router.pathname.includes('checkout');

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted || !user || user.givenName || isOnCheckoutPage) {
    return null;
  }

  const onClickSubmit = async () => {
    setSubmitting(true);
    await clientApiPatch(`/api/users/${user?.id}`, { givenName: firstName, familyName: lastName });
    await refresh();
  };

  return (
    <Slideout
      show
      canClose={false}
      aria-labelledby='contained-modal-title-vcenter'
      title={
        <div className='d-flex flex-column align-items-center justify-content-between'>
          <div className='text-primary'>Welcome in</div>
          <div className='mb-4'>What&apos;s your name?</div>
        </div>
      }
      submit={{
        variant: 'primary',
        className: 'rounded-2 w-100 m-0 py-3',
        disabled: submitting || !(firstName && lastName),
        onClick: onClickSubmit,
        title: submitting ? (
          <>
            <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
            <span className='visually-hidden'>Loading...</span>
          </>
        ) : (
          <span>Start Browsing</span>
        )
      }}
    >
      <div className='riforma-regular text-primary d-flex flex-column justify-content-center mb-5'>
        <Form>
          <Form.Group className='mb-4 mt-5' controlId='formFirstName'>
            <Form.Label>First name</Form.Label>
            <Form.Control
              autoFocus
              type='text'
              placeholder='First name'
              className={inputClasses}
              onChange={e => setFirstName(e.currentTarget.value)}
            />
          </Form.Group>
          <Form.Group className='mb-3' controlId='formLastName'>
            <Form.Label>Last name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Last name'
              className={inputClasses}
              onChange={e => setLastName(e.currentTarget.value)}
            />
          </Form.Group>
        </Form>
      </div>
    </Slideout>
  );
};
