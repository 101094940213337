import { HiArrowNarrowRight } from '@react-icons/all-files/hi/HiArrowNarrowRight';

export const ChatButtonContent = () => (
  <>
    Chat with us now
    <div className='absolute-centered-y d-flex align-items-center end-0 px-4'>
      <HiArrowNarrowRight />
    </div>
  </>
);
