import { FC, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { FormikValues, useFormik } from 'formik';
import { object, string } from 'yup';

import { PaymentTransaction } from 'types';

import { clientApiPost } from '../../../lib/api_helper';

const CARD_OPTIONS = {
  iconStyle: 'default',
  style: {
    base: {
      iconColor: '#6c757d',
      color: '#212529',
      fontWeight: 500,
      fontFamily: 'RiformaLLWeb-Regular, sans-serif',
      fontSize: '14px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#6c757d'
      }
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#DC3545'
    }
  }
} as const;

const formikInitialValues = {
  fullName: '',
  line1: '',
  line2: '',
  city: '',
  state: ''
};

const validationSchema = object({
  fullName: string().required('Full name is required'),
  line1: string().required('Street address is required'),
  line2: string(),
  city: string().required('City is required'),
  state: string().required('State is required')
});

type NewCardModalContentProps = {
  billingAccountId: number;
  onClose: () => void;
  postSubmitNewCard?: (card: PaymentTransaction['paymentCard']) => void;
  isDark?: boolean;
};

const formInputStyles = 'rounded-1 fs-14px p-3 bg-light shadow-none';

export const NewCardModalContent: FC<NewCardModalContentProps> = ({
  billingAccountId,
  onClose,
  postSubmitNewCard,
  isDark
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [globalError, setGlobalError] = useState<string>();
  const elements = useElements();
  const stripe = useStripe();

  const onClickSubmit = async (values: FormikValues) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setSubmitting(true);

    const card = elements.getElement(CardElement);

    if (card == null) {
      return;
    }

    const payload = await stripe.createToken(card, {
      name: values.fullName,
      address_line1: values.line1,
      address_line2: values.line2,
      address_city: values.city,
      address_state: values.state,
      address_country: 'US'
    });

    if (payload.error) {
      setSubmitting(false);
      setGlobalError(payload.error.message);
    } else {
      clientApiPost(
        `/api/billing_accounts/${billingAccountId}/cards`,
        JSON.stringify({ stripeCardToken: payload.token.id })
      )
        .then(cardData => {
          postSubmitNewCard?.(cardData as PaymentTransaction['paymentCard']);
          setGlobalError(undefined);
          setSubmitting(false);
          onClose();
        })
        .catch(() => {
          setGlobalError('Failed to save card');
          setSubmitting(false);
        });
    }
  };

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: onClickSubmit
  });

  return (
    <>
      <div
        className={classNames(
          'riforma-regular d-flex flex-column justify-content-center p-0 pt-4',
          isDark ? 'text-turquoise' : 'text-primary'
        )}
      >
        {globalError && (
          <Alert variant='danger' className='rounded-1 fs-14px'>
            {globalError}
          </Alert>
        )}
        <Form noValidate onSubmit={formik.handleSubmit}>
          <div className='fs-14px' style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            <Form.Group className='mb-3' controlId='formFullName'>
              <Form.Label>Full name</Form.Label>
              <Form.Control
                autoFocus
                type='text'
                name='fullName'
                placeholder='Full name'
                className={formInputStyles}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.fullName}
              />
              <Form.Control.Feedback type='invalid'>{formik.errors.fullName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3' controlId='formCardNumber'>
              <Form.Label>Card Information</Form.Label>
              <div className='border border-muted p-3 rounded-1 bg-light'>
                <CardElement options={CARD_OPTIONS} />
              </div>
            </Form.Group>
            <Form.Group className='mb-3' controlId='formLine1'>
              <Form.Label>Street address</Form.Label>
              <Form.Control
                type='text'
                name='line1'
                placeholder='Street address'
                className={formInputStyles}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.line1}
              />
              <Form.Control.Feedback type='invalid'>{formik.errors.line1}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3' controlId='formLine2'>
              <Form.Label>Apt, suite, etc</Form.Label>
              <Form.Control
                type='text'
                name='line2'
                placeholder='Apt, suite, etc'
                className={formInputStyles}
                onChange={formik.handleChange}
              />
            </Form.Group>
            <div className='row'>
              <Form.Group className='mb-3 col-8' controlId='formCity'>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type='text'
                  name='city'
                  placeholder='City'
                  className={formInputStyles}
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.city}
                />
                <Form.Control.Feedback type='invalid'>{formik.errors.city}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3 col-4' controlId='formState'>
                <Form.Label>State</Form.Label>
                <Form.Control
                  type='text'
                  name='state'
                  placeholder='State'
                  className={formInputStyles}
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.state}
                />
                <Form.Control.Feedback type='invalid'>{formik.errors.state}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <div>
            <Button
              variant={isDark ? 'turquoise' : 'primary'}
              className='p-3 mt-5 border rounded-2 d-flex align-items-center justify-content-center w-100 fs-14px'
              disabled={submitting}
              type='submit'
            >
              {!submitting && <span>Add card</span>}
              {submitting && (
                <>
                  <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
                  <span className='visually-hidden'>Loading...</span>
                </>
              )}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
