import { PaymentMethods } from 'consts/payment_methods';

import { ImageWithType, ListingImageType } from './base';
import { BuyerProfile } from './buyerProfile';
import { Issue } from './issue';
import { Model } from './model';
import { PaymentTransaction } from './paymentTransaction';
import { SellerProfile } from './sellerProfile';

export enum ListingStatus {
  Published = 'PUBLISHED',
  Ordered = 'ORDERED',
  Archived = 'ARCHIVED',
  Pending = 'PENDING_REVIEW',
  Draft = 'DRAFT',
  Rejected = 'REJECTED',
  Suspended = 'SUSPENDED'
}

export enum ListingCondition {
  Unworn = 'UNWORN',
  Preowned = 'PREOWNED'
}

export enum ProxyBidStatus {
  AWAITING_PAYMENT = 'AWAITING_PAYMENT',
  ACTIVE = 'ACTIVE',
  EXHAUSTED = 'EXHAUSTED',
  CANCELLED = 'CANCELLED'
}

export enum ProxyBidCancellationReason {
  ITEM_NO_LONGER_AVAILABLE = 'ITEM_NO_LONGER_AVAILABLE',
  PAYMENT_NEVER_RECEIVED = 'PAYMENT_NEVER_RECEIVED',
  OTHER = 'OTHER'
}

export type PricingModels = 'FIXED_PRICE' | 'AUCTION';

export type Listing = {
  id: number;
  sellerProfile: SellerProfile;
  model: Model;
  originalOwner: boolean;
  listedPriceCents: number;
  condition: ListingCondition;
  issues: { issue: Issue; answer?: string }[];
  images: ImageWithType[];
  accessories: {
    id: number;
    name: string;
  }[];
  listingChallenge: {
    id: number;
  };
  created: string;
  sourceListing?: Listing;
  updated: string;
  status: ListingStatus;
  version: number;
  reserved: boolean;
  priceCents: number;
  manufactureYear?: number;
  manufactureMonth?: number;
  offersAllowed?: boolean;
  desiredNetPayoutCents?: number;
  promotion?: {
    type: 'TIMED' | string;
    priceCents: number;
    timedDealExpiration: number;
  };
  overrideCommissionRateBips?: number;
  overrideSellerFeeCents?: number;
  overrideMinTotalFeeCents?: number;
  externalUrl?: string;
  externalSyncEnabled?: boolean;
  inventoryNumber?: string;
  returnsAllowed: boolean;
  flagged?: boolean;
  internalNotes?: string;
  strikethroughPriceCents?: number;
  tags:
    | {
        id: number;
        displayValue?: string;
        isPublic?: boolean;
        isRepeatable?: boolean;
        stableKey: 'GREAT_PRICE' | 'BEST_PRICE' | string;
        groupId?: number;
      }[]
    | [];
  // new properties auction related to auctions
  pricingModels: PricingModels[];
  activePricingModel: PricingModels;
  fixedPriceInfo: {
    priceCents: number;
    offersAllowed: boolean;
  };
  auctionInfo: {
    startDate: string;
    endDate: string;
    live: boolean;
    ended: boolean;
    hasReserve: boolean;
    metReserve?: boolean;
    startingPriceCents: number;
    numberOfBids: number;
    highestBid: Bid;
    nextBidCents: number;
    essay?: string | null;
    disclaimers?: ('BEZEL_OWNED' | 'GUARANTEED_BID' | 'CITES_REGULATED')[];
    requestsGuaranteedBid?: boolean;
    reserveCents?: number | null;
    estimatedValueRange?: {
      lowerBoundCents: number;
      upperBoundCents: number;
    };
  };
};

export type CreateListingBody = {
  model: number;
  sellerProfile: number;
  manufactureYear?: number;
  manufactureMonth?: number;
  priceCents?: number;
  condition: string;
  images?: { type: ListingImageType; image: number }[];
  status?: string;
  inventoryNumber?: string | number;
  externalUrl?: string;
  accessories?: number[];
  issues?: { issue: number; answer?: string }[];
  desiredNetPayoutCents: number | undefined;
};

export type ChallengeData = {
  hour: number;
  id: number;
  minute: number;
};

export type Bid = {
  id: number;
  bidderId: number;
  buyerProfile?: BuyerProfile;
  listing?: Listing;
  priceCents: number;
  created: string;
  updated: string;
};

export type ProxyBid = {
  id: number;
  status?: ProxyBidStatus;
  cancellationReason?: ProxyBidCancellationReason;
  listing: Listing;
  buyerProfile: BuyerProfile;
  transaction: PaymentTransaction;
  maxPriceCents: number;
  created: string;
  updated: string;
};

export type PaymentMethod = {
  type: PaymentMethods;
  details?: PaymentTransaction['paymentCard'];
};

export type InvoiceBody = {
  id?: number;
  buyerProfile?: number;
  listing?: number;
  listingVersion?: number;
  version?: number;
  shippingAddress?: number;
  paymentType?: string;
  discountCode?: string;
  addons?: string[];
};

export type Follow = {
  id: number;
  buyerProfile: BuyerProfile;
  listing: Listing;
};
