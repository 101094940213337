import { CSSProperties, FC } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import classNames from 'classnames';

import { headerHeight, SlideoutTestIds } from './constants';
import { SlideoutBackButton } from './SlideoutBackButton';
import { SlideoutCloseButton } from './SlideoutCloseButton';

export type SlideoutHeaderProps = {
  isDark?: boolean;
  canClose: boolean;
  onHide: () => void;
  onClickPrevious?: () => void;
  isTitleFixed?: boolean;
};

const buttonContainerStyles: CSSProperties = { width: 48, position: 'relative', zIndex: 3 };

export const SlideoutHeader: FC<SlideoutHeaderProps> = ({
  isDark,
  isTitleFixed,
  canClose,
  onClickPrevious,
  onHide
}) => (
  <Offcanvas.Header
    style={{ height: headerHeight, left: 0, right: 0 }}
    className={classNames('d-flex align-items-center justify-content-between', {
      'position-absolute': !isTitleFixed
    })}
  >
    <div style={buttonContainerStyles}>
      {onClickPrevious && <SlideoutBackButton onClickPrevious={onClickPrevious} isDark={isDark} />}
    </div>
    <div style={buttonContainerStyles}>
      {canClose && <SlideoutCloseButton testID={SlideoutTestIds.closeIcon} isDark={!!isDark} onClick={onHide} />}
    </div>
  </Offcanvas.Header>
);
