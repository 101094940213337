import { PaymentMethods } from 'consts/payment_methods';
import { PaymentMethod } from 'types';

export const isSelectedPaymentMethod = (paymentMethod: PaymentMethod, selectedPaymentMethod: PaymentMethod | null) => {
  if (!selectedPaymentMethod) {
    return false;
  }

  if (selectedPaymentMethod.type === paymentMethod.type) {
    if (selectedPaymentMethod.type === PaymentMethods.Card) {
      if (selectedPaymentMethod?.details?.id === paymentMethod?.details?.id) {
        return true;
      }
    } else {
      return true;
    }
  }

  return false;
};

export const paymentMethodTestIdPrefix = 'payment-list-modal-content-payment-method';
